<template>
  <div>
    <div v-if="isAdmin && enInvestorReportRedirect === 'TRUE'" class="mt-8">
      <v-btn @click="() => handleInvestmentRepor()" color="primary">{{
        $t("investment_report")
      }}</v-btn>
    </div>

    <div
      :style="`background-color: ${
        isAdmin ? 'var(--secondary)' : 'var(--primary)'
      }; border-radius: 5px`"
      class="mt-4"
    >
      <p style="color: var(--white); font-weight: normal" class="pa-2 mb-0">
        {{ $t("selected_for_user") }}
      </p>
    </div>

    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center">{{ $tc("company", 1) }}</th>
          <th class="text-center">{{ $tc("category", 1) }}</th>
          <th
            v-if="
              loggedUser.type === UserTypeEnum.Banker ||
              loggedUser.type === UserTypeEnum.Partner
            "
            class="text-center"
          >
            {{ $tc("action_title") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(company, index) in User.opportunities" :key="index">
          <td class="text-center">{{ company.name }}</td>
          <td class="text-center">
            {{ company.category ? company.category.name : "-" }}
          </td>
          <td
            v-if="
              loggedUser.type === UserTypeEnum.Banker ||
              loggedUser.type === UserTypeEnum.Partner
            "
            class="text-center"
          >
            <!-- <v-btn small color="primary" @click="user_info(user)">
              {{ $t("share") }}
            </v-btn> -->
            <v-btn small color="secondary" @click="viewBriefing(company)">
              {{ $t("see_more") }}
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <div
      :style="`background-color: ${
        isAdmin ? 'var(--secondary)' : 'var(--primary)'
      }; border-radius: 5px`"
      class="mt-4"
    >
      <p style="color: var(--white); font-weight: normal" class="pa-2 mb-0">
        {{ $t("requests") }}
      </p>
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center">{{ $tc("company", 1) }}</th>
          <th class="text-center">{{ $tc("category", 1) }}</th>
          <th class="text-center">{{ $t("value") }}</th>
          <th class="text-center">{{ $t("status") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(allocation, index) in User.companyInterests" :key="index">
          <td class="text-center">{{ allocation.company.name }}</td>
          <td class="text-center">
            {{
              allocation.company.category
                ? allocation.company.category.name
                : "-"
            }}
          </td>
          <td class="text-center">
            {{ formatToCurrency(allocation.currency, allocation.value) }}
          </td>
          <td class="text-center">
            {{ $t(`${parseRequestStatus(allocation.status)}`) }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <div
      :style="`background-color: ${
        isAdmin ? 'var(--secondary)' : 'var(--primary)'
      }; border-radius: 5px`"
      class="mt-4"
    >
      <p style="color: var(--white); font-weight: normal" class="pa-2 mb-0">
        {{ $t("invested") }}
      </p>
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center">{{ $tc("company", 1) }}</th>
          <th class="text-center">{{ $tc("category", 1) }}</th>
          <th class="text-center">{{ $t("value") }}</th>
          <th
            v-if="
              loggedUser.type === UserTypeEnum.Banker ||
              loggedUser.type === UserTypeEnum.Partner
            "
            class="text-center"
          >
            {{ $t("action_title") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(investment, index) in User.investments" :key="index">
          <td class="text-center">{{ investment.companyName }}</td>
          <td class="text-center">
            {{ investment.categoryName ? investment.categoryName : "-" }}
          </td>
          <td class="text-center">
            {{ gs.format_to_currency(investment.totalInvested) }}
          </td>
          <td
            v-if="
              loggedUser.type === UserTypeEnum.Banker ||
              loggedUser.type === UserTypeEnum.Partner
            "
            class="text-center"
          >
            <v-btn
              small
              color="secondary"
              @click="view_company(investment.companyId)"
            >
              {{ $t("see_more") }}
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { allocationStatusEnums } from "@/views/pages/allocations/AllocationStatusEnums";
import { UserTypeEnum } from "@/shared/enums/UserType.js";
import { redirectInvestorPlatform } from "@/shared/helpers/redirectToReactApp";

export default {
  name: "UserPortfolioInfo",

  components: {},

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    formatToCurrency: formatCurrency,
    loggedUser: null,
    isAdmin: false,
    enInvestorReportRedirect: process.env.VUE_APP_INVESTOR_REPORT_REDIRECT,
    UserTypeEnum,
    redirectInvestorPlatform,
  }),
  props: {
    User: Object,
  },
  computed: {},

  async created() {
    this.isAdmin = false;
    const user = JSON.parse(localStorage.getItem("user"));
    this.loggedUser = user;

    if (user.type === this.UserTypeEnum.Admin) {
      this.isAdmin = true;
    }
  },

  methods: {
    handleInvestmentRepor() {
      this.redirectInvestorPlatform(
        `/dashboard?investorId=${this.User.id}`,
        null
      );
    },
    view_company(companyId) {
      this.$router.push({
        path: `/companies/${companyId}`,
      });
    },
    viewBriefing(company) {
      const { companyId, briefingId } = company;

      this.$router.push({
        path: `/companies/${companyId}/briefing/${briefingId ? briefingId : 0}`,
      });
    },
    parseRequestStatus(requestStatus) {
      const literal = {
        [allocationStatusEnums.PLACEMENT_REVIEW]: "interest_status_pending",
        [allocationStatusEnums.FORMALIZATION]: "interest_status_approved",
        [allocationStatusEnums.DENIED]: "interest_status_declined",
        [allocationStatusEnums.PLACEMENT_REQUEST]:
          "interest_status_waiting_investor",
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]:
          "interest_status_approved",
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]:
          "interest_status_approved",
        [allocationStatusEnums.TRANSFER_FUNDS]: "interest_status_approved",
        [allocationStatusEnums.OPERATION_SETTING]: "interest_status_approved",
        [allocationStatusEnums.SENT_CAPTABLE]: "interest_status_approved",
        [allocationStatusEnums.CANCELLED]: "interest_status_declined",
      };
      return literal[requestStatus];
    },
  },
};
</script>
